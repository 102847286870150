import { Component } from "@angular/core";

@Component({
  selector: 'validation-tooltip',
  template: `
    <div class="custom-tooltip" [class.error-tooltip]="!isValid">
      <div class="tooltip-content">
        <i nz-icon [nzType]="isValid ? 'check-circle' : 'warning'"
           [class.valid]="isValid"
           [class.invalid]="!isValid">
        </i>
        <span>{{tooltipText}}</span>
      </div>
    </div>
  `,
  styles: [`
    .custom-tooltip {
      padding: 8px;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    }

    .error-tooltip {
      border-left: 2px solid #ff4d4f;
    }

    .tooltip-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    i {
      font-size: 14px;
      &.valid { color: #52c41a; }
      &.invalid { color: #ff4d4f; }
    }
  `]
})
export class CustomTooltip {
  params: any;
  tooltipText: string = '';
  isValid: boolean = true;

  agInit(params: any): void {
    this.params = params;
    this.tooltipText = params.value;
    this.isValid = !this.tooltipText.startsWith('Error');
  }
}
